import React from 'react'
// @ts-ignore
import { Circle } from 'better-react-spinkit'

const Spinner = ({on, color = '#3399ff'}: {on?: boolean, color?: string}) => {
    if (on) {
        return <div className="spinner-container">
            <div className="spinner-box">
                <Circle size={30} color={color} style={{display:'inline-block', padding: '0px'}}/>
            </div>
        </div>
    } else {
        return <div style={{display:'inline-block'}} />
    }
};

export default Spinner

