import React, { useState } from 'react';
import './App.css';
import { useParks } from "./hooks/useParks";
import { useSearchParams } from "react-router-dom";
import Spinner from "./components/Spinner";
import ParksList from "./components/ParksList";
import Footer from "./components/Footer";

const defaultParams = {
  width: 600,
  height: 300,
  // orientation: 'portrait',
  orientation: 'landscape',
  // orientation: 'square',
};

if (process.env.REACT_APP_ENV === 'development') {
    /*defaultParams.organisationId = {
        acme: 5196731213938688,
        alicia: 5199156310179840,
        felafel: 6613816615895040,
        felafel2: 4785904572956672,
        travis: 5181461934112768,
        iot: 4826489648316416,
        atacorp: 4837562560544768,
        nigel: 5066688714244096,
        paul: 5072322704703488,
        james: 5072780924026880,
        toby: 5152858844430336,
        demo: 5165521079107584,
        martin: 5182368709083136,
        wanna: 5192695605624832,
        corpy: 5204113188978688,
        test: 5969153953366016,
        parkable: 6291377046421504,
        brody: 6291550573166592,
    }['acme'];*/
}

const useParams = () => {
  const [params] = useSearchParams();

  const p = {} as any;
  const it = params.entries();
  let q = it.next();
  while (!q.done) {
    const [key, val] = q.value;
    p[key] = val;
    q = it.next();
  }
  return p;
}

const App = () => {

  const [didSetParentSize, setDidSetParentSize] = useState(false);

  const urlParams = useParams();
  const params = {...defaultParams, ...urlParams};

  const organisationId = params.organisationId;
  const parks = params.parks ? JSON.parse(params.parks) : undefined;
  const {data: parkData, mutate, error: parkError} = useParks(organisationId);

  const organisationName = parks.organisationName || parkData?.name || 'Loading...';

  //console.log('props', parkData, params, urlParams, organisationName);

  const header = () => {
    if (params.orientation === 'landscape') {
      return <div className="app-header">
        <table>
            <tbody>
                <tr>
                    <td>
                        <div className="Carparks-Available">{'Carparks Available'}</div>
                        <div className="organisation-name">{organisationName}</div>
                    </td>
                </tr>
            </tbody>
        </table>
      </div>
    } else {
      return <div className="app-header">
        <div className="Carparks-Available">{'Carparks Available'}</div>
        <div className="organisation-name">{organisationName}</div>
      </div>
    }
  };

  const content = () => {
    if (parkError) {
      return <div className="app-header">
        <div className="organisation-name">{'Unable to get info'}</div>

        <a onClick={() => mutate()}>
          <div className="booknow-button">{'Refresh'}</div>
        </a>
      </div>
    } else if (!parkData?.name) {
      return <div>
        <div className="app-header">
          <Spinner on/>
          <ParksList parks={parkData?.parks} params={params}/>
        </div>
      </div>
    }

    return <div>
      {header()}
      <div className="hello-parks">
        <ParksList parks={parkData.parks} params={params}/>
      </div>

    </div>
  };

  //console.log('this.props', props);
  return <div
      ref={ref => {
        //console.log('app ref parent', ref?.parentElement);
        const parent = ref?.parentElement;
        if (!didSetParentSize && parent) {
          setDidSetParentSize(true);
          //console.log('app ref parent', parent.style);
          parent.style.setProperty('width', params.width);
          parent.style.setProperty('height', params.height);
        }
      }}
      className="App"
      style={{width: params.width, height: params.height}}>

    {content()}

    <Footer orientation={params.orientation}/>
  </div>
}

export default App;
