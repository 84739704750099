import useSWR from 'swr';
import {get} from './fetcher';

export type OrganisationWidgetResponse = {
    name: string,
    parks: WidgetPark[]
}

export type WidgetGroup = {
    id: number;
    name: string;
    numberOfParks: number;
}

export type WidgetPark = {
    id: number,
    name: string,
    numberOfParks: number,
    groups: WidgetGroup[]
}

export const useParks = (organisationId: string | undefined | null) => {
    return useSWR<OrganisationWidgetResponse>(organisationId ? `v2/organisations/${organisationId}/widget/parks` : null, get);
}
