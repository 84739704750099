import React from 'react';
import logo from '../images/parkableLogoBlack.svg';
import './Footer.css';

const Footer = (props: any) => {

    if (props.orientation === 'square') {
        return <div className="footer">
            <div className="logo">
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://parkable.com">
                    <img src={logo} alt="logo" className="footer-logo"/>
                </a>
            </div>
        </div>
    } else {
        return <div className="footer">
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://parkable.com">
                <img src={logo} alt="logo" className="footer-logo"/>
            </a>
        </div>
    }

}

export default Footer;
