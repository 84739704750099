import React from 'react';
import _ from 'lodash';
import './ParksList.css';
import location from '../images/location-black@2x.png';
import { WidgetGroup, WidgetPark } from "../hooks/useParks";

export type GroupData = {

}

export type ParkData = {
    groups: undefined | GroupData[]
}

export type ParksData = {
    [parkId: string]: ParkData
}

export interface Params {
    orientation?: 'landscape' | 'portrait' | 'square',
    showGroups?: boolean,
    parks?: string,
    width?: number,
}

const ParksList = ({parks, params}: { parks: WidgetPark[] | undefined, params: Params }) => {

    const limitLength = (s: string, n: number) => {
        if (!s) {return ''}

        const words = s.split(/[\s]+/);
        const shortenedWords = [...words.map((_, i) => i)].reverse()
            .map(n => words.filter((w,i) => i <= n).join(' '))
            .filter(s => s.length < n)[0];
        return (s.length > n && shortenedWords) ? `${shortenedWords}...` : s;
    };

    const horizontal = params.orientation !== 'portrait';

    let parkOrGroups: Array<WidgetPark | WidgetGroup> = (parks || [])
        .filter(park => park.name && park.numberOfParks >= 0)
        .map((park: WidgetPark): WidgetPark => ({
            ...park,
            name: limitLength(park.name, 30)
        }));

    const {parks: urlParkData} = params;

    if (urlParkData) {
        const parkData: ParksData = JSON.parse(urlParkData);
        //console.log('p2r', parkOrGroups, parkData);

        parkOrGroups = parkOrGroups
            .filter(park => !!parkData[`${park.id}`])
            .map(park => {
                const {groups, ...paramsPark} = parkData[park.id];

                return {
                    ...park,
                    ...paramsPark,
                    groups: !(params.showGroups && groups) ? [] :
                        (park as WidgetPark).groups?.map(g => ({
                            ...g,
                            ...groups[g.id]
                        }))
                }
            });

        if (params.showGroups) {
            parkOrGroups = _.chain(parkOrGroups)
                .map(park => (park as WidgetPark).groups?.
                    filter(g => !parkData[park.id]?.groups || parkData[park.id]?.groups?.[g.id]))
                .compact()
                .flatMap()
                .value();
        }
    }


    const style = {} as React.CSSProperties;
    if (horizontal && params.width && parkOrGroups.length > 0) {
        style.width = params.width / parkOrGroups.length;
    }

    return <>
        {_.sortBy(parkOrGroups, park => park.name)
        .map((parkOrGroup, i) => <div
            key={i}
            className={`park ${horizontal ? 'park-horizontal' : ''} ${['park-light','park-mid','park-dark'][i % 3]}`}
            style={style}
        >
            <div className="park-address">
                <img src={location} alt="location" className="location-pin"/>
                {parkOrGroup.name}</div>
            <div className="park-spaces">{parkOrGroup.numberOfParks}</div>
        </div>)}
    </>;
}

export default ParksList;
