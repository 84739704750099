import axios from "axios";

const http = axios.create({});

const Api = (() => {
    if ((process.env.REACT_APP_ENV === 'develop')) {
        return 'https://parkable-dev.appspot.com/api/';
    }
    if ((process.env.REACT_APP_ENV === 'staging')) {
        return 'https://parkable-staging.appspot.com/api/';
    }
    return 'https://api.parkable.com/api/';
})();

// Base configuration
http.interceptors.request.use(async (config) => {
    /*const token = await firebaseService.getAuthToken();

    config.headers = {
        "X-Parkable-API-Version": DEFAULT_API_VERSION,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        Authorization: token ?? retrieveCookies()?.[Cookie.Token] ?? "",
        ...config.headers, // Allow overriding
    };*/
    config.baseURL = Api;
    return config;
});

async function tryRequest<T>(req: () => Promise<T>, retries = 3): Promise<T> {
    try {
        return await req();
    } catch (err) {
        throw err;
    }
}

export const get = async <T = any>(url: string, params?: any, headers?: any) => {
    const response = await tryRequest(() => http.get<T>(url, {
        params: params ? { ...params } : undefined,
        headers: { ...headers },
    }));
    return response.data;
};

export const getMany = async <T = any>(urls: string[], params?: any, headers?: any) => {
    const dataList = await Promise.all(
        urls.map(async (url) => (
            await http.get<T>(url, {
                params: params ? { ...params } : undefined,
                headers: { ...headers },
            })
        ).data)
    );
    return dataList;
};

export const post = async <T = any>(url: string, body?: any, headers?: any) => {
    const response = await tryRequest(() => http.post<T>(
        url,
        body, // removed {...data} as it stripped the type and axios didnt handle form data
        { headers }
    ));
    return response.data;
};

export const patch = async <T = any>(url: string, body?: any, headers?: any) => {
    const response = await tryRequest(() => http.patch<T>(url, { ...body }, { headers: { ...headers } }));
    return response.data;
};

export const put = async <T = any>(url: string, body?: any, headers?: any) => {
    const response = await tryRequest(() => http.put<T>(url, { ...body }, { headers: { ...headers } }));
    return response.data;
};

export const del = async <T = any>(url: string, headers?: any) => {
    const response = await tryRequest(() => http.delete<T>(url, { headers: { ...headers } }));
    return response.data;
};

export const fetcher = async (key: string, paramsStr: string | undefined) => {
    const params = JSON.parse(paramsStr ?? "{}");
    return get(key, params);
};
